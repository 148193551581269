






































































import Vue from "vue";
import { required, sameAs } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
import { formatDate } from "@/utils/helpers";

export default Vue.extend({
    name: "ChangePasswordModal",
    props: {
        isLoading: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            form: {
                comment: "",
            },
            submitted: false,
            loading: false,
            isOldPasswordVisible: false,
            isNewPasswordVisible: false,
            isNewConfirmPasswordVisible: false,
            passwordFields: {
                oldPassword: "",
                newPassword: "",
                confirmPassword: "",
            },
        };
    },
    computed: {
        ...mapGetters({ profileData: "auth/getUserData" }),
    },
    validations(): any {
        return {
            passwordFields: {
                oldPassword: { required },
                newPassword: { required },
                confirmPassword: { required, sameAsPassword: sameAs("newPassword") },
            },
        };
    },
    methods: {
        formatDate,
        ...mapActions({
            changePassword: "auth/changePassword",
            fetchProfile: "auth/fetchProfile",
        }),
        validateForm() {
            this.submitted = true;
            this.$v.$touch();
            const invalid = this.$v.passwordFields.$invalid;
            if (!invalid) {
                this.savePassword();
            }
        },
        async savePassword() {
            this.loading = true;
            const payload = { ...this.passwordFields };
            try {
                const response = await this.changePassword(payload);
                if (response.status === 200) {
                    this.$bvToast.toast("Success!", { title: "Password Change Successful!", variant: "success", solid: true });
                    await this.fetchProfile();
                    this.$bvModal.hide("modal-change-password");
                    this.passwordFields.oldPassword = "";
                    this.passwordFields.newPassword = "";
                    this.passwordFields.confirmPassword = "";
                }
            } catch (e) {
                this.$bvToast.toast("Something went wrong!", { title: "Unable to change password!", variant: "danger", solid: true });
            }
            this.loading = false;
        },
    },
});
